<template>
  <div>
<!--    <div class="breadCurmb mt-3">-->
<!--      <label>-->
<!--        <router-link to="/admin/home">{{ $t("Home") }}</router-link>-->
<!--      </label>-->
<!--      <span>/</span>-->
<!--      <label active>{{ $t("Sales") }}</label>-->
<!--      <span>/</span>-->
<!--      <label style="cursor:pointer" @click="goBack">-->
<!--        <span>-->
<!--          {{ $t('العناوين') }}-->
<!--        </span>-->

<!--      </label>-->
<!--      <span>/</span>-->
<!--      <label>{{  $t("Edit") + ' بيانات العنوان للمندوب ' }}</label>-->
<!--    </div>-->

<!--    <v-toolbar-title class="mt-3">{{ $t("العناوين") }}</v-toolbar-title>-->


    <v-card-text>
          <v-row class="mt-3">
            <v-col md="12">
              <v-alert border="top" color="green lighten-2" dark>
                <h4>{{ $t("تعديل بيانات") }}</h4>
              </v-alert>
            </v-col>
          </v-row>
          <v-container fluid>
            <v-row v-if="selectedCustomer.name">
              <v-col class="py-0" :cols="6">
                <div >
                  <label>{{ $t("إسم المندوب") }}</label>
                  <v-text-field  class="d-block my-2"
                                 type="text" hide-details
                                 dense
                                 outlined disabled
                                 v-model="selectedCustomer.name"
                  ></v-text-field>
                </div>

              </v-col>
              <v-col class="py-0" :cols="6">
                <div >
                  <label>{{ $t("Mobile") }}</label>
                  <v-text-field  class="d-block my-2"
                                 type="text" hide-details dense
                                 outlined disabled v-model="selectedCustomer.phone"
                  ></v-text-field>
                </div>

              </v-col>
            </v-row>

            <v-row>
              <v-col class="py-0" :cols="3">
                <div
                    :class="{
                    'has-error': errors.has(`addEditValidation.City`),
                  }"
                >
                  <label>{{ $t("مكان التوصيل ") }}</label>
                  <v-text-field  class="d-block my-2"
                                 disable hide-details dense
                                 outlined disabled v-model="formData.type"
                ></v-text-field>
                </div>

                <div
                    class="help-block"
                    v-if="errors.has(`addEditValidation.City`)"
                >
                  {{ errors.first(`addEditValidation.City`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="3">
                <div
                    :class="{
                    'has-error': errors.has(`addEditValidation.City`),
                  }"
                >
                  <label>{{ $t("City") }}</label>
                  <v-autocomplete
                      class="d-block my-2"
                      name="City"
                      data-vv-scope="addEditValidation"
                      v-validate="'required'"
                      :data-vv-as="$t('City')"
                      hide-details
                      dense
                      outlined
                      v-model="formData.city_id"
                      item-value="id"
                      item-text="name"
                      :items="cityList"
                      @change="getNeighborhoodList(formData.city_id)"
                      clearable
                  ></v-autocomplete>
                </div>

                <div
                    class="help-block"
                    v-if="errors.has(`addEditValidation.City`)"
                >
                  {{ errors.first(`addEditValidation.City`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="3">
                <div
                    :class="{
                    'has-error': errors.has(`addEditValidation.Neighborhood`),
                  }"
                >
                  <label>{{ $t("Neighborhood") }}</label>
                  <v-autocomplete
                      class="d-block my-2"
                      name="Neighborhood"
                      data-vv-scope="addEditValidation"
                      v-validate="'required'"
                      :data-vv-as="$t('Neighborhood')"
                      hide-details
                      dense
                      outlined
                      v-model="formData.branch_id"
                      item-value="id"
                      item-text="name"
                      :items="neighborhoodList"
                      clearable
                  ></v-autocomplete>
                </div>

                <div
                    class="help-block"
                    v-if="errors.has(`addEditValidation.Neighborhood`)"
                >
                  {{ errors.first(`addEditValidation.Neighborhood`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="3">
                <div
                    :class="{
                    'has-error': errors.has(`addEditValidation.company`),
                  }"
                >
                  <label>{{ $t("Company") }}</label>
                  <v-autocomplete
                      class="d-block my-2"
                      name="company"
                      data-vv-scope="addEditValidation"
                      v-validate="'required'"
                      :data-vv-as="$t('Company')"
                      hide-details
                      dense
                      outlined
                      v-model="formData.company_id"
                      item-value="id"
                      item-text="name"
                      :items="companyList"
                      clearable
                  ></v-autocomplete>
                </div>

                <div
                    class="help-block"
                    v-if="errors.has(`addEditValidation.company`)"
                >
                  {{ errors.first(`addEditValidation.company`) }}
                </div>
              </v-col>


              <v-col class="py-0" :cols="3">
                <div
                    :class="{
                    'has-error': errors.has(`addEditValidation.Period`),
                  }"
                >
                  <label>{{ $t("Period") }}</label>
                  <v-autocomplete
                      class="d-block my-2"
                      name="Period"
                      data-vv-scope="addEditValidation"
                      v-validate="'required'"
                      :data-vv-as="$t('Period')"
                      hide-details
                      dense
                      outlined
                      v-model="formData.period"
                      item-value="id"
                      item-text="name"
                      :items="periodList"
                      clearable
                  ></v-autocomplete>
                </div>

                <div
                    class="help-block"
                    v-if="errors.has(`addEditValidation.Period`)"
                >
                  {{ errors.first(`addEditValidation.Period`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="6">
                <div
                    :class="{
                    'has-error': errors.has(`addEditValidation.HomeNumber`),
                  }"
                >
                  <label>{{ $t("HomeNumber") }}</label>
                  <v-text-field
                      class="d-block my-2"
                      type="text"
                      name="HomeNumber"
                      data-vv-scope="addEditValidation"
                      v-validate="''"
                      :data-vv-as="$t('HomeNumber')"
                      hide-details
                      dense
                      outlined
                      v-model="formData.home_number"
                  ></v-text-field>
                </div>

                <div
                    class="help-block"
                    v-if="errors.has(`addEditValidation.HomeNumber`)"
                >
                  {{ errors.first(`addEditValidation.HomeNumber`) }}
                </div>
              </v-col>


              <v-col class="py-0" :cols="6">
                <div
                    :class="{
                    'has-error': errors.has(`addEditValidation.address`),
                  }"
                >
                  <label>{{ $t("Address") }}</label>
                  <v-text-field
                      class="d-block my-2"
                      type="text"
                      name="address"
                      data-vv-scope="addEditValidation"
                      v-validate="''"
                      :data-vv-as="$t('Address')"
                      hide-details
                      dense
                      outlined
                      v-model="formData.address"
                  ></v-text-field>
                </div>

                <div
                    class="help-block"
                    v-if="errors.has(`addEditValidation.address`)"
                >
                  {{ errors.first(`addEditValidation.address`) }}
                </div>
              </v-col>


              <v-col class="py-0"  :cols="6">
                <div
                    :class="{
                    'has-error': errors.has(`addEditValidation.link_location`),
                  }"
                >
                  <label> {{ $t("عنوان المستخدم") }}</label>
                  <v-text-field
                      class="d-block my-2"
                      type="text"
                      name="link_location"
                      data-vv-scope="addEditValidation"
                      v-validate="'required'"
                      :data-vv-as="$t('link_location')"
                      hide-details
                      dense
                      outlined
                      v-model="formData.link_location"
                  ></v-text-field>
                </div>

                <div class="help-block"  v-if="errors.has(`addEditValidation.link_location`)">
                  {{ errors.first(`addEditValidation.link_location`) }}
                </div>
              </v-col>


              <v-col class="py-0" :cols="6">
                <div
                    :class="{
                    'has-error': errors.has(`addEditValidation.notes2`),
                  }"
                >
                  <label>{{ $t("Notes") }}</label>
                  <v-textarea
                      class="d-block my-2"
                      type="text"
                      name="notes2"
                      rows="4"
                      data-vv-scope="addEditValidation"
                      v-validate="''"
                      :data-vv-as="$t('Notes')"
                      hide-details
                      dense
                      outlined
                      v-model="formData.notes"
                  ></v-textarea>
                </div>

                <div
                    class="help-block"
                    v-if="errors.has(`addEditValidation.notes2`)"
                >
                  {{ errors.first(`addEditValidation.notes2`) }}
                </div>
              </v-col>
            </v-row>
          </v-container>
          <v-container fluid v-if="activeSubmit">
            <v-row class="float-left">
              <v-btn     color="success" dark  outlined  @click="save">
                {{ $t("حفظ بيانات ") }}
              </v-btn>
            </v-row>
          </v-container>
        </v-card-text>



    <BlockUI v-if ="loadClicked" :message="msg">
      <div class="sk-wave">
        <div class="sk-rect sk-rect1"></div>
        <div class="sk-rect sk-rect2"></div>
        <div class="sk-rect sk-rect3"></div>
        <div class="sk-rect sk-rect4"></div>
        <div class="sk-rect sk-rect5"></div>
      </div>
    </BlockUI>


  </div>
</template>

<script>
export default {
  name: "createAddress",
  data() {
    return {
      activeSubmit: true,
      user: localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user"))
          : [],
      defaultDays: [],
      loadClicked: false,
      msg: 'برجاء الانتظار لحين اكتمال العملية',
      activeTab: 0,
      selectedCustomer: {
        name: null,
        phone: null,
        email: null
      },
      formData: {
        type    : null,
        city_id : null,
        branch_id: null,
        home_address: null,
        period: null,
        home_number: null,
        company_id: null,
        group_name_id: null,
        address: null,
        notes: null,
        link_location:null
      },

      cityList: [],
      neighborhoodList: [],
      companyList: [],
      periodList: [
        {
          id: 1,
          name: "صباحي",
        },
        {
          id: 2,
          name: "مسائي",
        },
      ],
    };
  },
  created() {

    if (this.$route.params.type_sub && this.$route.params.type_sub == 'renewal' ){
      this.activeSubmit = false;
    }
    this.$store.dispatch(`subscribe/companyList`).then((res) => {
      this.companyList = res.data;
    });


    this.$store.dispatch(`subscribe/cityList`).then((res) => {
      this.cityList = res.data;
    });

    const loading = this.$loading({
      lock: true, text: "Loading", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)",
    });

    this.$store.dispatch(`newSubscribe/findDeliveryData`, this.$route.params.id).then((res) => {
      this.formData = res.data;
      this.selectedCustomer = res.data.driver
    }).then((e) => {
      this.getNeighborhoodList(this.formData.city_id);
      loading.close();
    });


  },
  watch: {
    'formData.city_id': function(newCityId, oldCityId) {
      if (oldCityId && newCityId !== oldCityId) {
        this.formData.branch_id = null; // Reset branch_id when city_id changes
      }
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getNeighborhoodList(id) {
        this.$store.dispatch(`subscribe/branchList`, id).then((res) => {
          this.neighborhoodList = res.data;
        });

    },
    save() {
      this.$validator.validateAll("addEditValidation").then((valid) => {

        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");

        let sendData = JSON.parse(JSON.stringify(this.formData));

        sendData.subscription_id = parseInt(this.$route.params.id);

        this.$store.dispatch(`newSubscribe/updateLocationForDelivery`, sendData).then((res) => {
          this.notifySuccess("Save", res);
          this.$router.push({ name: "subscribe" });
        }).catch((error) => {

          let errorsArray = [];

          // Check if the error object has response and data properties
          if (error.response && error.response.data) {
            const responseData = error.response.data;

            // Check if the response data has an 'errors' array
            if (Array.isArray(responseData.errors)) {
              // If 'errors' is an array, add all error messages to the errorsArray
              errorsArray = responseData.errors;
            } else if (typeof responseData.errors === 'object') {
              // If 'errors' is an object, iterate over its values and add them to errorsArray
              errorsArray = Object.values(responseData.errors);
            } else if (responseData.message) {
              // If there's a single error message, add it to the errorsArray
              errorsArray.push(responseData.message);
            }
          } else {
            // If the error object doesn't have expected properties, log the error
            console.error('Unexpected error:', error);
            errorsArray.push('An unexpected error occurred.');
          }

          this.errorsApi = errorsArray;
        })

      });

    },
  },
};
</script>

